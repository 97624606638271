/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { ReentrantObjectPool as e } from "../../core/ReentrantObjectPool.js";
import { afterDispatch as t, isValueInUse as r } from "../../core/accessorSupport/watch.js";
class o {
  constructor(r, o) {
    this._owner = o, this._properties = {}, this._afterDispatchHandle = null;
    for (const t in r) {
      const o = r[t],
        s = new e(o, void 0, void 0, 2, 2);
      this._properties[t] = {
        pool: s,
        acquired: []
      };
    }
    this._afterDispatchHandle = t(() => this._release());
  }
  destroy() {
    this._afterDispatchHandle && (this._afterDispatchHandle.remove(), this._afterDispatchHandle = null);
    for (const e in this._properties) {
      const t = this._properties[e];
      for (const e of t.acquired) r(e) || t.pool.release(e);
      t.pool.destroy(), t.pool = null, t.acquired = null;
    }
    this._properties = null, this._owner = null;
  }
  get(e) {
    const t = this._owner._get(e),
      r = this._properties[e];
    let o = r.pool.acquire();
    for (r.acquired.push(o); o === t;) r.acquired.push(o), o = r.pool.acquire();
    return o;
  }
  _release() {
    for (const e in this._properties) {
      const t = this._properties[e];
      let o = 0;
      for (const e of t.acquired) r(e) ? t.acquired[o++] = e : t.pool.release(e);
      t.acquired.length = o;
    }
  }
}
export { o as PropertiesPool };
/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import "../../core/has.js";
import "../../core/Logger.js";
import { EventMatch as e } from "./EventMatch.js";
class t {
  constructor(e) {
    this._manager = null, this._incoming = {}, this._outgoing = {}, this._incomingEventMatches = null, this._incomingEventTypes = null, this._outgoingEventTypes = null, this._hasSideEffects = e;
  }
  get incomingEventMatches() {
    if (!this._incomingEventMatches) {
      this._incomingEventMatches = [];
      for (const e in this._incoming) {
        const t = this._incoming[e];
        for (const e of t) this._incomingEventMatches.push(e.match);
      }
    }
    return this._incomingEventMatches;
  }
  get incomingEventTypes() {
    return this._incomingEventTypes || (this._incomingEventTypes = this.incomingEventMatches.map(e => e.eventType)), this._incomingEventTypes;
  }
  get outgoingEventTypes() {
    return this._outgoingEventTypes || (this._outgoingEventTypes = Object.keys(this._outgoing)), this._outgoingEventTypes;
  }
  get hasSideEffects() {
    return this._hasSideEffects;
  }
  get hasPendingInputs() {
    return !1;
  }
  onInstall(e) {
    this._manager || (e.setEventCallback(e => this._handleEvent(e)), e.setUninstallCallback(() => this._onUninstall()), this._manager = e);
  }
  onUninstall() {}
  registerIncoming(t, i, s) {
    let o;
    "function" == typeof i ? (s = i, o = []) : o = i || [];
    const a = "string" == typeof t ? new e(t, o) : t,
      h = () => {
        this._incomingEventTypes = null, this._incomingEventMatches = null;
      },
      r = e => {
        const t = this._incoming[e.match.eventType];
        if (t) {
          const n = t.indexOf(e);
          t.splice(n, 1), h(), this._manager && this._manager.updateDependencies();
        }
      },
      g = new n(a, s, {
        onPause: r,
        onRemove: r,
        onResume: e => {
          const t = this._incoming[e.match.eventType];
          t && !t.includes(e) && (t.push(e), h(), this._manager && this._manager.updateDependencies());
        }
      });
    let c = this._incoming[a.eventType];
    return c || (c = [], this._incoming[a.eventType] = c), c.push(g), h(), this._manager && this._manager.updateDependencies(), g;
  }
  registerOutgoing(e) {
    if (this._outgoing[e]) throw new Error("There is already a callback registered for this outgoing InputEvent: " + e);
    const t = new i(e, {
      onEmit: (e, t, n, i) => {
        this._manager?.emit(e.eventType, t, n, i);
      },
      onRemove: e => {
        delete this._outgoing[e.eventType], this._manager?.updateDependencies();
      }
    });
    return this._outgoing[e] = t, this._outgoingEventTypes = null, this._manager && this._manager.updateDependencies(), t;
  }
  startCapturingPointer(e) {
    this._manager?.setPointerCapture(e, !0);
  }
  stopCapturingPointer(e) {
    this._manager?.setPointerCapture(e, !1);
  }
  refreshHasPendingInputs() {
    this._manager?.refreshHasPendingInputs();
  }
  _onUninstall() {
    this._manager && (this.onUninstall(), this._manager = null);
  }
  _handleEvent(e) {
    const t = this._incoming[e.type];
    if (t) for (const n of t) if (n.match.matches(e) && (n.callback?.(e), e.shouldStopPropagation())) break;
  }
}
class n {
  constructor(e, t, n) {
    this.match = e, this._callback = t, this._handler = n;
  }
  pause() {
    this._handler.onPause(this);
  }
  resume() {
    this._handler.onResume(this);
  }
  remove() {
    this._handler.onRemove(this);
  }
  get callback() {
    return this._callback;
  }
}
class i {
  constructor(e, t) {
    this.eventType = e, this._removed = !1, this._handler = t;
  }
  emit(e, t, n) {
    this._removed || this._handler.onEmit(this, e, t, n);
  }
  remove() {
    this._removed = !0, this._handler.onRemove(this);
  }
}
export { t as InputHandler };